import axios from 'axios'
import moment from 'moment'
const detailEndpoint = '/user_api/v1/dashboard/detailed_view'
const detailUrl = (
  process.env.VUE_APP_USER_API_ENDPOINT +
  detailEndpoint
)
const compactEndpoint = '/user_api/v1/dashboard/compact_view'
const compactUrl = (
  process.env.VUE_APP_USER_API_ENDPOINT +
  compactEndpoint
)

function parseDetailItems (item) {
  return {
    file_type: item.file_type,
    arrival_date: item.arrival_date ? moment(item.arrival_date).format('lll') : 'N/A',
    start_date: item.start_date ? moment(item.start_date).format('lll') : 'N/A',
    end_date: item.end_date ? moment(item.end_date).format('lll') : 'N/A',
    file_name: item.file_name ? item.file_name : 'N/A',
    file_part_of_day: item.file_part_of_day,
    status: item.status
  }
}

function parseCompactItems (item) {
  return {
    date: item.date,
    number_of_files: item.number_of_files,
    number_of_processed_files: item.number_of_processed_files,
    number_of_errored_files: item.number_of_errored_files,
    number_of_missing_files: item.number_of_missing_files,
    number_of_in_progress_files: item.number_of_in_progress_files,
    number_of_not_processed_files: item.number_of_not_processed_files
  }
}
function getFileDashboardDetailData (parameters) {
  const { date } = parameters
  const apiUrl = detailUrl + `?date=${date}`
  const requestPromise = axios.get(apiUrl)
    .catch(error => {
      console.error({ _: 'getFileDashboardDetailData failed', parameters, detailUrl, error })
      throw error
    })
  return requestPromise
}
function getFileDashboardCompactData (parameters) {
  const { startDate, endDate } = parameters
  const apiUrl = compactUrl + `?start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}`
  const requestPromise = axios.get(apiUrl)
    .catch(error => {
      console.error({ _: 'getFileDashboardCompactData failed', parameters, detailUrl, error })
      throw error
    })
  return requestPromise
}
export default {
  namespaced: true,
  state: {
    dashboardData: [],
    dashboardListData: [],
    ongoingQuery: false
  },
  getters: {
    dashboardData (state) {
      return state.dashboardData
    },
    dashboardListData (state) {
      return state.dashboardListData
    },
    ongoingQuery (state) {
      return state.ongoingQuery
    }
  },
  mutations: {
    setDashboardData (state, data) {
      state.dashboardData = data
    },
    setDashboardListData (state, data) {
      state.dashboardListData = data
    },
    setOngoingQuery (state, value) {
      state.ongoingQuery = value
    }
  },
  actions: {
    async getFileDashboardDetailData ({ commit }, parameters) {
      commit('setOngoingQuery', true)
      const requestPromise = getFileDashboardDetailData(parameters)
        .then(response => {
          const data = response.data
          const logs = data.map(parseDetailItems)
          commit('setDashboardData', logs)
        })
        .catch(error => {
          console.error({ _: 'getFileDashboardDetailData failed', parameters, error })
        })
        .finally(() => {
          commit('setOngoingQuery', false)
        }
        )
      return requestPromise
    },
    async getFileDashboardCompactData ({ commit }, parameters) {
      commit('setOngoingQuery', true)
      const requestPromise = getFileDashboardCompactData(parameters)
        .then(response => {
          const data = response.data
          const logs = data.map(parseCompactItems)
          commit('setDashboardListData', logs)
        })
        .catch(error => {
          console.error({ _: 'getFileDashboardCompactData failed', parameters, error })
        })
        .finally(() => {
          commit('setOngoingQuery', false)
        }
        )
      return requestPromise
    }
  }
}
