<template>
  <div
    id="loading-spinner-modal"
    class="loading-spinner-modal"
    v-show="ongoingQuery"
  >
    <div class="loading-spinner-container">
      <div class="vertical-align">
        <div class="spinner-content">
          <div class="spinner-animation">
            <b-spinner class="spinner" :label="messages.getLabelLoading()" />
          </div>
          <div class="spinner-text">
            {{ messages.getLabelListingItems() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import { NOTIFICATION_EMAIL, NOTIFICATION_FEEDBACK, NOTIFICATION_SMS, NOTIFICATION_PUSH_NOTIFICATION } from '@/constants/store-names'

export default {
  name: 'LoadingSpinnerCover',
  data () {
    return {
      messages
    }
  },
  computed: {
    ongoingQuery () {
      return (
        this.$store.getters['statusMonitor/ongoingQuery'] ||
        this.$store.getters['stockManagement/ongoingQuery'] ||
        this.$store.getters['stockCount/ongoingQuery'] ||
        this.$store.getters['materialCount/ongoingQuery'] ||
        this.$store.getters[`${NOTIFICATION_SMS}/ongoingQuery`] ||
        this.$store.getters[`${NOTIFICATION_EMAIL}/ongoingQuery`] ||
        this.$store.getters[`${NOTIFICATION_FEEDBACK}/ongoingQuery`] ||
        this.$store.getters[`${NOTIFICATION_PUSH_NOTIFICATION}/ongoingQuery`] ||
        this.$store.getters['dashboard/ongoingQuery']
      )
    }
  },
  watch: {
    ongoingQuery (newValue) {
      if (newValue === true) {
        // Disable scrolling in the item list to avoid bad UX.
        // This might not be the best place to control scrolling lock.
        // FIXME: When using overflow-hidden to disable scrolling, the
        // "scrollTop" value returns 0 and the list view is resetted to
        // show topmost items. @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTop
        // The scroll position is returned after the search request has finished
        // but user can see in the backround the wrong postion while the request
        // is waiting for response. @see OrderListMobile.vue:fetchNextPage
        document.querySelector('.view-wrapper').classList.add('overflow-hidden')
      } else {
        // Enable scrolling.
        document.querySelector('.view-wrapper').classList.remove('overflow-hidden')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loading-spinner-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, .88);
  z-index: 123456789;
  padding-bottom: 49px; /* This is to counter the bottom bar just in case */
}
.loading-spinner-container {
  position: relative;
  display: flex;
}
.loading-spinner-container .vertical-align {
  position: relative;
  /* margin-top: auto; */
  width: 100%;
  display: flex;
  flex-direction: row; /* Align the child horizontally */
  justify-content: center; /* Align the child horizontally */
}
.loading-spinner-container .spinner-content {
  /* Spinner animation and text */
  display: flex;
  flex-direction: column; /* Animation on top and text on bottom */
  justify-content: center;
}
.loading-spinner-container .spinner-content .spinner-animation {
  width: 36px;
  height: 36px;
  margin: 0 auto; /* Center the animation to the text */
}
</style>
